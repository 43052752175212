import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, Wrapper, Title, Headline, Recent } from '../components'
import website from '../../config/website'

import theme from '../styles/theme'

const Hero = styled.header`
  display: grid;
  align-items: center;
  justify-items: center;
  padding-top: 2em;
`

const HeroInner = styled(Wrapper)`
  text-align: center;
  align-self: start;

  padding: 1em;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`

const HeroText = styled.div`
  font-size: 1rem;
  color: grey;
  line-height: 1.4;
  margin-bottom: 2rem;
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  li {
    display: inline;
    &:not([data-name='social-entry-0']) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.greyDark};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.primary};
        text-decoration: none;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2.369rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`

const IndexWrapper = Wrapper.withComponent('main')

class Index extends Component {
  render() {
    const {
      data: { homepage, social, posts, projects },
    } = this.props

      const allPosts = posts.edges
      console.log(allPosts)

    return (
      <Layout ethos={homepage.data.ethos.text}>
        <IndexWrapper id={website.skipNavId} style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Headline uid={homepage.data.headline.uid} post={homepage.data.headline.document[0].data} />
          <Recent headlineId={homepage.data.headline.id} posts={allPosts} />
        </IndexWrapper>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.shape({
          text: PropTypes.string.isRequired,
        }),
        content: PropTypes.shape({
          html: PropTypes.string.isRequired,
        }),
      }),
    }),
    social: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    posts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
    projects: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        ethos {
          text
        }
        headline {
          id
          uid
          document {
            data {
              title {
                text
              }
              featured_image {
                url
                alt
              }
              brief {
                text
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(
        sort: { fields: last_publication_date, order: DESC },
        filter: { uid: { ne: "prismic-dummy-post" } }
    ) {
      edges {
        node {
          uid
          prismicId
          data {
            title {
              text
            }
            featured_image {
              url
            }
            brief {
              text
            }
            date(formatString: "DD.MM.YYYY")
          }
        }
      }
    }
  }
`
